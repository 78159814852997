import React from 'react'

function History({ calculationHistory = [], deleteCalculation }) {
  return (
    <div className="history-container">
      {calculationHistory.length === 0 ? (
        <p>Ingen gemte beregninger.</p>
      ) : (
        calculationHistory
          .slice()
          .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sortér efter dato for at vise nyeste først
          .map((calc, index) => (
            <div key={index} className="history-item">
              <div className="history-row">
                <strong>Dato:</strong> {formatDate(calc.date)}
              </div>
              <div className="history-row">
                <strong>Kulhydrater:</strong> {calc.carbs ?? 'N/A'} gram
              </div>
              <div className="history-row">
                <strong>Blodsukker:</strong> {calc.currentBloodSugar ?? 'N/A'}{' '}
                mmol/L
              </div>
              <div className="history-row">
                <strong>Total Insulin:</strong>{' '}
                {calc.totalInsulin ? calc.totalInsulin.toFixed(2) : 'N/A'}{' '}
                enheder
              </div>
              <div className="history-row">
                <strong>Kulhydrat Dosis:</strong>{' '}
                {calc.carbDose ? calc.carbDose.toFixed(2) : 'N/A'} enheder
              </div>
              <div className="history-row">
                <strong>Korrektionsdosis:</strong>{' '}
                {calc.correctionDose ? calc.correctionDose.toFixed(2) : 'N/A'}{' '}
                enheder
              </div>
              {calc.note && (
                <div className="history-row">
                  <strong>Note:</strong> {calc.note}
                </div>
              )}
              <div className="history-row">
                <strong>Bruger:</strong> {calc.user ?? 'Ukendt'}
              </div>
              <button
                className="button delete"
                onClick={() => deleteCalculation(calc.id)}
              >
                Slet
              </button>
            </div>
          ))
      )}
    </div>
  )
}

// Eksporter formatDate funktionen separat
function formatDate(isoString) {
  const date = new Date(isoString)

  const day = String(date.getDate()).padStart(2, '0') // dd
  const month = String(date.getMonth() + 1).padStart(2, '0') // mm
  const year = date.getFullYear() // yyyy

  const hours = String(date.getHours()).padStart(2, '0') // hh
  const minutes = String(date.getMinutes()).padStart(2, '0') // mm
  const seconds = String(date.getSeconds()).padStart(2, '0') // ss

  // Returnerer i formatet: dd-mm-yyyy hh:mm:ss
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
}

export default History
