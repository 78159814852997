// src/components/Modal.js
import React from 'react'
import './AppStyles.css'

function Modal({ isOpen, onClose, title, children, modalClassName }) {
  if (!isOpen) return null

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className={`modal-content ${modalClassName || ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>{title}</h2>
        {children}
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  )
}

export default Modal
