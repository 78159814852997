import React, { useEffect } from 'react'

function Toast({ message, onClose }) {
  useEffect(() => {
    if (message) {
      console.log('Toast timer started')
      const timer = setTimeout(() => {
        console.log('Toast timer ended')
        onClose() // Luk toasten efter 2 sekunder
      }, 2000) // 2 sekunder

      // Ryd timeren, hvis komponenten unmountes eller opdateres
      return () => clearTimeout(timer)
    }
  }, [message, onClose]) // Kør kun, hvis `message` eller `onClose` ændrer sig

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#28a745',
        color: '#fff',
        padding: '15px 30px',
        borderRadius: '10px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        zIndex: 1000,
      }}
    >
      {message}
      <button
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: '#fff',
          fontSize: '1.2rem',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        ✕
      </button>
    </div>
  )
}

export default Toast
