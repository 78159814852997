// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCfBe2B4c9g8-51gNnBXifHUFN7yjSoG7I',
  authDomain: 'insulin-app-88116.firebaseapp.com',
  projectId: 'insulin-app-88116',
  storageBucket: 'insulin-app-88116.appspot.com',
  messagingSenderId: '831945833347',
  appId: '1:831945833347:web:6390582269f85f7ddb54b6',
  measurementId: 'G-DJ3ZYNNN1Q',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)

// Optional: Include analytics if you plan to use it
// import { getAnalytics } from 'firebase/analytics';
// const analytics = getAnalytics(app);

export { db }
