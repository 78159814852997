import React, { useState, useEffect, useCallback } from 'react'
import Toast from './Toast'
import Modal from './Modal'
import './AppStyles.css'
import FixedValues from './FixedValues'
import History from './History'
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from 'firebase/firestore'
import { db } from '../firebase'

// Move calculationsCollection outside the component to prevent re-creation
const calculationsCollection = collection(db, 'test-calculations')

function App() {
  const [carbs, setCarbs] = useState('')
  const [currentBloodSugar, setCurrentBloodSugar] = useState('')
  const [totalInsulin, setTotalInsulin] = useState(null)
  const [carbDose, setCarbDose] = useState(null)
  const [correctionDose, setCorrectionDose] = useState(5)
  const [note, setNote] = useState('')
  const [insulinSensitivity, setInsulinSensitivity] = useState(6)
  const [carbRatio, setCarbRatio] = useState(25)
  const [bloodSugarGoal, setBloodSugarGoal] = useState(5)
  const [calculationHistory, setCalculationHistory] = useState([])
  const [recentCalculation, setRecentCalculation] = useState(null)
  const [alarm, setAlarm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [user, setUser] = useState(localStorage.getItem('user') || '')
  const [isUserModalOpen, setIsUserModalOpen] = useState(!user)
  const [inputUser, setInputUser] = useState('')
  const [includeCorrectionDose, setIncludeCorrectionDose] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  // Use useCallback with inline debounce
  const throttledSetCalculationHistory = useCallback((history) => {
    let timeout
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setCalculationHistory(history)
    }, 1000)
  }, [])

  // Fetch real-time data from Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(calculationsCollection, (snapshot) => {
      const history = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      throttledSetCalculationHistory(history)

      const now = new Date()
      const recent = history.find((calc) => {
        const calcDate = new Date(calc.date)
        return now - calcDate < 2 * 60 * 60 * 1000 // Under 2 hours
      })
      if (recent) {
        setRecentCalculation(recent)
        setAlarm(true)
      } else {
        setAlarm(false)
      }
    })

    return () => unsubscribe() // Cleanup snapshot listener on unmount
  }, [throttledSetCalculationHistory]) // No need for `calculationsCollection` dependency since it's static

  // Load saved fixed values from localStorage
  useEffect(() => {
    const savedInsulinSensitivity = localStorage.getItem('insulinSensitivity')
    const savedCarbRatio = localStorage.getItem('carbRatio')
    const savedBloodSugarGoal = localStorage.getItem('bloodSugarGoal')

    if (savedInsulinSensitivity)
      setInsulinSensitivity(parseFloat(savedInsulinSensitivity))
    if (savedCarbRatio) setCarbRatio(parseFloat(savedCarbRatio))
    if (savedBloodSugarGoal) setBloodSugarGoal(parseFloat(savedBloodSugarGoal))
  }, [])

  const calculateInsulin = () => {
    const carbDoseValue = carbs / parseFloat(carbRatio)

    let correctionDoseValue = 0
    if (includeCorrectionDose) {
      correctionDoseValue =
        (currentBloodSugar - parseFloat(bloodSugarGoal)) /
        parseFloat(insulinSensitivity)
    }

    const totalDose = carbDoseValue + correctionDoseValue

    setCarbDose(carbDoseValue)
    setCorrectionDose(correctionDoseValue)
    setTotalInsulin(totalDose)
  }

  const saveCalculation = async () => {
    if (isSaving) return // Prevent multiple saves

    setIsSaving(true)

    const newCalculation = {
      carbs: parseFloat(carbs),
      currentBloodSugar: parseFloat(currentBloodSugar),
      totalInsulin: parseFloat(totalInsulin),
      carbDose: parseFloat(carbDose),
      correctionDose: parseFloat(correctionDose),
      note,
      insulinSensitivity,
      carbRatio,
      bloodSugarGoal,
      user,
      date: new Date().toISOString(),
    }

    try {
      const docRef = await addDoc(calculationsCollection, newCalculation)

      setCalculationHistory([
        ...calculationHistory,
        { id: docRef.id, ...newCalculation },
      ])

      setToastMessage('Beregning gemt!')
      setCarbs('')
      setCurrentBloodSugar('')
      setTotalInsulin(null)
      setCarbDose(null)
      setCorrectionDose(null)
      setNote('')

      const now = new Date()
      const calcDate = new Date(newCalculation.date)
      if (now - calcDate < 2 * 60 * 60 * 1000) {
        setRecentCalculation(newCalculation)
        setAlarm(true)
      } else {
        setAlarm(false)
      }
    } catch (error) {
      console.error('Fejl ved gemning i Firestore:', error)
      setToastMessage('Der opstod en fejl ved gemning.')
    } finally {
      setIsSaving(false)
    }
  }

  const deleteCalculation = async (id) => {
    try {
      const docRef = doc(db, 'test-calculations', id)
      await deleteDoc(docRef)

      const updatedHistory = calculationHistory.filter((calc) => calc.id !== id)
      setCalculationHistory(updatedHistory)

      setToastMessage('Beregning slettet!')
    } catch (error) {
      console.error('Fejl ved sletning i Firestore:', error)
      setToastMessage('Der opstod en fejl ved sletning.')
    }
  }

  const selectUser = () => {
    setUser(inputUser)
    localStorage.setItem('user', inputUser)
    setIsUserModalOpen(false)
  }

  const handleCloseToast = useCallback(() => {
    setToastMessage('')
  }, [])

  return (
    <div className="container">
      <button className="user-button" onClick={() => setIsUserModalOpen(true)}>
        {user || 'Vælg Bruger'}
      </button>

      <h1 className="title">Insulindosis Beregner</h1>

      <Modal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        title="Indtast dit navn"
      >
        <input
          type="text"
          value={inputUser}
          onChange={(e) => setInputUser(e.target.value)}
          placeholder="Dit navn"
          className="input"
        />
        <button className="button primary" onClick={selectUser}>
          Gem Bruger
        </button>
      </Modal>

      <div className="card">
        <label className="label">
          Kulhydrater (gram):{' '}
          <span className="subtext">Nuværende kulhydratratio: {carbRatio}</span>{' '}
        </label>
        <input
          type="number"
          value={carbs}
          onChange={(e) => setCarbs(e.target.value)}
          className="input"
        />

        <label className="label">
          Nuværende Blodsukker (mmol/L):{' '}
          <span className="subtext">Blodsukker mål: {bloodSugarGoal}</span>{' '}
        </label>
        <input
          type="number"
          value={currentBloodSugar}
          onChange={(e) => setCurrentBloodSugar(e.target.value)}
          className="input"
        />

        <label className="label">Inkluder korrektionsdosis?</label>
        <input
          type="checkbox"
          checked={includeCorrectionDose}
          onChange={(e) => setIncludeCorrectionDose(e.target.checked)}
        />
        <span className="subtext">
          Fjern denne fra beregningen, hvis han fornyeligt har fået korrigeret
          blodsukkeret.
        </span>

        <button className="button primary" onClick={calculateInsulin}>
          Beregn Insulindosis
        </button>
      </div>

      {/* Alarm hvis der er en registrering indenfor 2 timer */}
      {alarm && (
        <p className="alarm">
          Bemærk: Seneste registrering er foretaget kl.{' '}
          {new Date(recentCalculation.date)
            .getHours()
            .toString()
            .padStart(2, '0') +
            ':' +
            new Date(recentCalculation.date)
              .getMinutes()
              .toString()
              .padStart(2, '0')}
          , og der skal gå cirka 2 timer før man korrigerer blodsukkeret igen.
        </p>
      )}

      {totalInsulin !== null && (
        <div className="card">
          <h3 className="result-title">
            Total Insulindosis: {totalInsulin.toFixed(2)} enheder
          </h3>
          <p>Kulhydrat Dosis: {carbDose.toFixed(2)} enheder</p>
          <p>Korrektionsdosis: {correctionDose.toFixed(2)} enheder</p>

          <label className="label">Note:</label>
          <input
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="input"
            placeholder="Tilføj en note (valgfrit)"
          />

          <button
            className="button save"
            onClick={saveCalculation}
            disabled={isSaving}
          >
            {isSaving ? 'Gemmer...' : 'Gem Beregning'}
          </button>
        </div>
      )}

      <div className="button-group">
        <button
          onClick={() => setIsModalOpen(!isModalOpen)}
          className="button secondary"
        >
          Rediger Faste Værdier
        </button>
        <button
          onClick={() => setIsHistoryModalOpen(!isHistoryModalOpen)}
          className="button history"
        >
          Vis Historik
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Rediger Faste Værdier"
      >
        <FixedValues
          insulinSensitivity={insulinSensitivity}
          carbRatio={carbRatio}
          bloodSugarGoal={bloodSugarGoal}
          setInsulinSensitivity={setInsulinSensitivity}
          setCarbRatio={setCarbRatio}
          setBloodSugarGoal={setBloodSugarGoal}
          onClose={() => setIsModalOpen(false)}
        />
      </Modal>

      <Modal
        isOpen={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        title="Beregninger Historik"
      >
        <History
          calculationHistory={calculationHistory}
          deleteCalculation={deleteCalculation}
        />
      </Modal>

      {toastMessage && (
        <Toast message={toastMessage} onClose={handleCloseToast} />
      )}
    </div>
  )
}

export default App
