import React, { useState, useEffect } from 'react'

function FixedValues({
  insulinSensitivity,
  carbRatio,
  bloodSugarGoal,
  setInsulinSensitivity,
  setCarbRatio,
  setBloodSugarGoal,
  onClose,
}) {
  const [localInsulinSensitivity, setLocalInsulinSensitivity] =
    useState(insulinSensitivity)
  const [localCarbRatio, setLocalCarbRatio] = useState(carbRatio)
  const [localBloodSugarGoal, setLocalBloodSugarGoal] = useState(bloodSugarGoal)

  useEffect(() => {
    setLocalInsulinSensitivity(insulinSensitivity)
    setLocalCarbRatio(carbRatio)
    setLocalBloodSugarGoal(bloodSugarGoal)
  }, [insulinSensitivity, carbRatio, bloodSugarGoal])

  const handleSave = () => {
    setInsulinSensitivity(localInsulinSensitivity)
    setCarbRatio(localCarbRatio)
    setBloodSugarGoal(localBloodSugarGoal)

    // Save to localStorage
    localStorage.setItem(
      'insulinSensitivity',
      localInsulinSensitivity.toString()
    )
    localStorage.setItem('carbRatio', localCarbRatio.toString())
    localStorage.setItem('bloodSugarGoal', localBloodSugarGoal.toString())

    onClose() // Close the modal
  }

  return (
    <div>
      <label>Insulinfølsomhed (mmol/L per enhed):</label>
      <input
        type="number"
        value={localInsulinSensitivity}
        onChange={(e) => setLocalInsulinSensitivity(Number(e.target.value))}
        className="modal-input"
      />

      <label>Kulhydratratio (gram per enhed):</label>
      <input
        type="number"
        value={localCarbRatio}
        onChange={(e) => setLocalCarbRatio(Number(e.target.value))}
        className="modal-input"
      />

      <label>Blodsukkermål (mmol/L):</label>
      <input
        type="number"
        value={localBloodSugarGoal}
        onChange={(e) => setLocalBloodSugarGoal(Number(e.target.value))}
        className="modal-input"
      />

      <button className="button save" onClick={handleSave}>
        Gem Ændringer
      </button>
    </div>
  )
}

export default FixedValues
